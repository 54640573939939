<template>
    <div>
        <v-app-bar dense absolute text class="fixedToolbar" style="z-index: 2;">
            <v-toolbar-title v-bind:class="{'headline grey-text': !drawer, 'padLeft headline grey-text': drawer }">Transactions</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-tooltip left v-if="!speedDial">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click.prevent="$router.push({ path: '/dashboard/addtransaction/expense' })"
                           slot="activator"
                           small
                           fab
                           text
                           middle
                           right>
                        <v-icon>fas fa-plus</v-icon>
                    </v-btn>
                </template>
                <span>Add Transaction</span>
            </v-tooltip>

            <!--Speed Dial-->
            <v-speed-dial v-model="speedDial"
                          :direction="direction"
                          :transition="transition">

                <template v-slot:activator>
                    <v-btn slot="activator"
                           v-model="speedDial"
                           text
                           small
                           fab>
                        <v-icon v-if="!speedDial">fas fa-ellipsis-v</v-icon>
                        <v-icon v-else>fas fa-times</v-icon>
                    </v-btn>
                </template>

                <!-- Search Button -->
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click.prevent="speedDialChanged('search')"
                               slot="activator"
                               fab
                               text
                               small
                               middle
                               right>
                            <v-icon>fas fa-search</v-icon>
                        </v-btn>
                    </template>
                    <span>Search</span>
                </v-tooltip>
                <!--End Of Search Button-->
                <!-- Filter Button -->
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click.prevent="speedDialChanged('filter')"
                               slot="activator"
                               fab
                               text
                               small
                               middle
                               right>
                            <v-icon>fas fa-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>Filter</span>
                </v-tooltip>
                <!-- End Of Filter Button -->
            </v-speed-dial>
            <!--End Of Speed Dial-->
            
        </v-app-bar>

        <!--Card Design-->
        <v-container fluid class="card-flex-toolbar noPadding ">

            <DateSkipper v-bind:type="datePickerType" v-bind:drawer="drawer" v-bind:startDate="datePeriod" v-on:updateDate="dateChanged($event)" v-on:next="nextDate()" v-on:previous="previousDate()"></DateSkipper>

            <v-layout row wrap style="height:100%" class="padContent">

                <v-flex v-bind:class="[showDataOnSide ? 'md6' : 'md12']">
                    <v-container fluid
                                 style="min-height: 0;"
                                 grid-list-lg>

                        <!--Search Box-->
                        <template v-if="speedDialOptions.viewSearch && !speedDialOptions.viewFilter">
                            <Search v-on:gSearch="updateSearch($event)"></Search>
                        </template>
                        <!--End Of Search Box-->

                        <!--No Data Message-->
                        <template v-if="itemsMinusDeleted.length === 0">
                            <NoData></NoData>
                        </template>
                        <!--End Of No Data Message-->

                        <!--Filter Box-->
                        <template v-if="speedDialOptions.viewFilter">
                            <Filter v-bind:filterBy="filterBy" v-on:filterData="getfilteredData($event)"></Filter>
                        </template>
                        <!--End Of Filter Box-->

                        <v-layout row wrap>

                            <ListItems v-bind:liItem="{type: 'transaction', data: itemsMinusDeleted}" ref="listItem" v-on:Edit="redirectToEditPage($event)" v-on:Delete="DeleteTransaction($event)" v-on:clickedItem="itemclicked($event)"></ListItems>

                        </v-layout>
                    </v-container>
                </v-flex>
                <v-flex md6 v-if="showDataOnSide && $vuetify.breakpoint.mdAndUp" style="height:100%">

                    <ListItemData v-bind:lItem="listItemData" v-on:show="adjustShow($event)" v-on:EditItem="redirectToEditPage($event)" v-on:DeleteItem="DeleteTransaction($event)"></ListItemData>

                </v-flex>
            </v-layout>

            <!--End Of Card Design-->

        </v-container>

            <!-- Delete Account Dialog Box -->
            <v-layout row justify-center>
                <v-dialog v-model="deleteDialog" max-width="290">
                    <v-card>
                        <v-card-title class="headline">Delete</v-card-title>
                        <v-card-text>Are you sure you want to delete this Transaction?</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text="text" @click.native="deleteDialog = false">Disagree</v-btn>
                            <v-btn color="green darken-1" text="text" @click.native="DeleteTransactionAccept($v.deleteTransaction)">Agree</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-layout>
            <!-- End Of Delete Account Dialog Box -->

    </div>
</template>


<script>
  import { validationMixin } from 'vuelidate'

  import { listItemFunctions } from '../../javascript/listItemManager'
  import { globalFunctions } from '../../javascript/globalFunctions'
  import { dateManager } from '../../javascript/dateManager'
 

  export default {
    mixins: [validationMixin],
    components: {
        ListItems: () => import('../../components/listItem/listItems.vue'),
        NoData: () => import('../../components/noData/noData.vue'),
        Search: () => import('../../components/search/search.vue'),
        ListItemData: () => import('../../components/listItem/listItemData.vue'),
        DateSkipper: () => import('../../components/dateSkipper/dateSkipper.vue'),
        Filter: () => import('../../components/filter/filter.vue'),
    },
    props: ['drawer'],
    data() {
      return {
        direction: 'left',
        speedDial: false,
        transition: 'slide-y-reverse-transition',
        moreOptions: false,
        search: '',
        datePickerType: 'month',
        datePeriod: new Date().toISOString().substr(0, 7),
        speedDialOptions: {
          viewSearch: false,
          viewFilter: false
        },
        filterBy: {
          transactionType: 'None',
          budget: 'None',
          category: 'None',
          currency: 'None',
          paymentType: 'None',
          show: false
        },
        addItem: false,
        datemenu: false,
        timemenuvisibilty: false,
        transactionItemsSearch:'',
        currency: [],
        account: ["All Accounts"],
        transferAccount: [],
        selectedAccount: 'All Accounts',
        category: [],
        budget: [],
        selectedPeriod: 'All',
        addDialog: false,
        editDialog: false,
        deleteDialog: false,
        deleteTransaction: {
          id: 0,
          transactionName: '',
          account: '',
          transactionType: '',
          transactionAmount: 0,
          paymentType: '',
          comment: '',
          category: '',
          currency: '',
          items: [],

          transactionDate: '',
          transactionTime: null,

          transferFrom: '',
          transferTo: '',

          recurringTransaction: false,

          //Recurring Transactions
          repeat: 1,
          cycle: '',
          period: 'Forever',
          numberOfCycles: 1,
          goesOffFirst: '',

          //State of Current Object
          objectState: '',
          show: false
        },
        transactionType: 1,
        items: [],
        itemsMinusDeleted: [],
        showDataOnSide: false,
        selectedItem: 0
      }
    },
    computed: {
      listItemData() {
        return listItemFunctions.convertItemsMinusDeletedTolItem(this.itemsMinusDeleted, this.selectedItem, 'transaction')
      }
    },
    methods: {
        adjustShow: function (data) {
            this.showDataOnSide = data
        },
        dateChanged: function (date) {
            this.datePeriod = date

            this.getItems()
        },
        nextDate: function () {
            this.datePeriod = dateManager.nextDate(this.datePeriod)

            this.getItems()
        },
        previousDate: function () {
            this.datePeriod = dateManager.previousDate(this.datePeriod)

            this.getItems()
        },
      launchAddTransactionAndPopulateDateTime: function() {
        this.addDialog = true
        let date = new Date()

        let month = date.getMonth() + 1
        if (month < 10) {
          month = "0" + month
        }

        let day = date.getDate()
        if (day < 10) {
          day = "0" + day
        }

        this.currentTransaction.transactionDate = date.getFullYear() + "-" + month + "-" + day

        this.currentTransaction.transactionTime = date.getHours() + ":" + date.getMinutes()

      },
      //TransactionCheck: function ($v) {
      //  $v.$touch(); //Triggers Error Messages

      //  if ($v.account.$invalid || $v.category.$invalid || $v.transactionAmount.$invalid || $v.currency.$invalid || $v.paymentType.$invalid) {
      //    return false
      //  }
      //  return true
      //},
      //AddTransactionCheck: function ($v) {
      //  if (this.TransactionCheck($v)) {
      //    this.AddTransaction();
      //  }
      //},
      //AddTransaction: function () {
      //  if (this.currentTransaction.transactionAmount < 0 || !(/^[0-9]+$/.test(this.currentTransaction.transactionAmount)) || this.currentTransaction.transactionAmount === "") { this.currentTransaction.transactionAmount = 0 }

      //  let newEntry = JSON.parse(JSON.stringify(this.currentTransaction))

      //  this.addDialog = false

      //  newEntry.id = this.getLargestIdFromItems() + 1

      //  this.$store.commit('addNewTransactionToStore', newEntry)
      //  this.resetCurrentTransactionObject()
      //},
      //getLargestIdFromItems: function () {
      //  let largest = 0
      //  for (let i = 0; i < this.items.length; i++) {
      //    if (this.items[i].id > largest) {
      //      largest = this.items[i].id
      //    }
      //  }
      //  return largest
      //},
      //UpdateEditedTransaction: function ($v) {
      //  if (this.TransactionCheck($v)) {
      //    this.editDialog = false

      //    this.editTransaction.objectState = "Update"

      //    this.$store.commit('updateTransactioninStore', this.editTransaction)
      //  }
      //},
      //EditTransaction: function (item) {
      //  for (let i = 0; i < this.items.length; i++) {
      //    if (this.items[i] === item) {
      //      let editEntry = JSON.parse(JSON.stringify(this.items[i]))
      //      this.editTransaction = editEntry
      //      break
      //    }
      //  }

      //  this.editDialog = true
      //},
      DeleteTransactionAccept: function () {
        this.deleteDialog = false

        this.deleteTransaction.objectState = "Delete"

        this.$store.commit('markTransactionAsDeleted', this.deleteTransaction)
      },
      DeleteTransaction: function (item) {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].id === item) {
            this.deleteTransaction = this.items[i]
            break
          }
        }

        this.deleteDialog = true
      },
      //resetCurrentTransactionObject: function () {
      //  this.currentTransaction.transactionType = "expense"
      //  this.currentTransaction.transactionAmount = 0
      //  this.currentTransaction.paymentType = ""
      //  this.currentTransaction.comment = ""
      //  this.currentTransaction.category = ""
      //  this.currentTransaction.currency = ""
      //  this.currentTransaction.transactionDate = ""
      //  this.currentTransaction.transactionTime = null
      //},
      removeDeletedTransactionsFromItems: function () {
          this.itemsMinusDeleted = globalFunctions.stringify(this.items)

        for (let i = 0; i < this.itemsMinusDeleted.length; i++) {
          if (this.itemsMinusDeleted[i].objectState === 'Delete') {
            this.itemsMinusDeleted.splice(i, 1)
          }
        }
        
      },
      redirectToEditPage: function (item) {
        this.$router.push({ path: '/dashboard/edittransaction/' + item })
      },
      getitemsFromStoreAndRemoveDeletedItems: function () {
          this.items = globalFunctions.stringify(this.$store.state.Transactions)

        ///Remove deleted items from items array
        this.removeDeletedTransactionsFromItems()
      },
      getfilteredData: function (filter) {
        this.filterBy.transactionType = filter.transactionType
        this.filterBy.budget = filter.budget
        this.filterBy.category = filter.category
        this.filterBy.currency = filter.currency
        this.filterBy.paymentType = filter.paymentType
        this.viewFilter = filter.show

        this.filterdata()
      },
      filterdata: function () {
        this.getitemsFromStoreAndRemoveDeletedItems()

        //Filter By TransactionType
        if (this.filterBy.transactionType !== 'None') {
          for (let i = 0; i < this.itemsMinusDeleted.length; i++) {
            if (this.itemsMinusDeleted[i].transactionType.toLowerCase() !== this.filterBy.transactionType.toLowerCase()) {
              this.itemsMinusDeleted.splice(i, 1);
            }
          }
        }

        //Filter By Budget
        if (this.filterBy.budget !== 'None') {
          for (let i = 0; i < this.itemsMinusDeleted.length; i++) {
            if (this.itemsMinusDeleted[i].budget.toLowerCase() !== this.filterBy.budget.toLowerCase()) {
              this.itemsMinusDeleted.splice(i, 1);
            }
          }
        }

        //Filter By Category
        if (this.filterBy.category !== 'None') {
          for (let i = 0; i < this.itemsMinusDeleted.length; i++) {
            if (this.itemsMinusDeleted[i].category.toLowerCase() !== this.filterBy.category.toLowerCase()) {
              this.itemsMinusDeleted.splice(i, 1);
            }
          }
        }

        //Filter By Currency
        if (this.filterBy.currency !== 'None') {
          for (let i = 0; i < this.itemsMinusDeleted.length; i++) {
            if (this.itemsMinusDeleted[i].currency.toLowerCase() !== this.filterBy.currency.toLowerCase()) {
              this.itemsMinusDeleted.splice(i, 1);
            }
          }
        }

        //Filter By PaymentType
        if (this.filterBy.paymentType !== 'None') {
          for (let i = 0; i < this.itemsMinusDeleted.length; i++) {
            if (this.itemsMinusDeleted[i].paymentType.toLowerCase() !== this.filterBy.paymentType.toLowerCase()) {
              this.itemsMinusDeleted.splice(i, 1);
            }
          }
        }

      },
      itemclicked: function (item) {
        this.showDataOnSide = item.show
        this.selectedItem = item.id
        },
      getItems: function () {
          this.itemsMinusDeleted = []
          let newDate = dateManager.returnDateRange(this.datePeriod)


        //Check if Search is empty  
        //if (this.search === '' || this.search === null) {
        //    //Get All Items from item
        //    this.itemsMinusDeleted = JSON.parse(JSON.stringify(this.items))
        //    return
        //}

        //Remove Transactions that does not meet search criteria 
          for (let i = 0; i < this.items.length; i++) {
              if (dateManager.isWithinDateRange(newDate.startDate, newDate.endDate, this.items[i].transactionBalances[0].transactionDate)) {
                  this.itemsMinusDeleted.push(this.items[i])
              }
          }
      },
      getSearchItems: function () {
        this.itemsMinusDeleted = []

        //Check if Search is empty  
        if (this.search === '' || this.search === null) {
          //Get All Items from item
          this.itemsMinusDeleted = JSON.parse(JSON.stringify(this.items))
          return
        }

        //Remove Transactions that does not meet search criteria 
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].transactionName.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
            this.itemsMinusDeleted.push(this.items[i])
          }
        }
      },
      updateSearch: function (search) {
        this.search = search

        this.getSearchItems()
      },
      speedDialChanged: function (change) {
        this.speedDialOptions = globalFunctions.viewFilterSearchChanged(this.speedDialOptions, change)
      }
    },
    beforeMount() {
        this.getitemsFromStoreAndRemoveDeletedItems()
        this.getItems()


      let cat = this.$store.state.Categories
      for (let i = 0; i < cat.length; i++) {
        this.category.push(cat[i].categoryName)
      }

      let cur = this.$store.state.Currencies
      for (let i = 0; i < cur.length; i++) {
        this.currency.push(cur[i].currencyName)
      }

      let acc = this.$store.state.Accounts
      for (let i = 0; i < acc.length; i++) {
        this.account.push(acc[i].accountName)
        this.transferAccount.push(acc[i].accountName)
      }
      
    },
  }
</script>
